import { Status } from '@netcurio-ui/common'
import { NetcurioAlert, NetcurioButton, Severity } from '@netcurio-ui/components'
import { t } from 'i18next'
import React from 'react'
import { Invoice } from '../../../../types'
import { themeButtonReview } from '../utilities/defaultValues'
import styles from './InvoiceAlert.module.scss'

interface InvoiceAlertProps {
	seeMessageInvoiceError: boolean
	showLegacyMessage: boolean
	invoice: Invoice
	isStandaloneDocument: boolean
	isMissingLink: boolean
	executeValidation: () => void
}

const InvoiceAlert = ({
	seeMessageInvoiceError,
	showLegacyMessage,
	invoice,
	isStandaloneDocument,
	isMissingLink,
	executeValidation
}: InvoiceAlertProps) => {
	if (showLegacyMessage || invoice.cancellation_reason) {
		return (
			<div className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Warning}>
					{invoice.cancellation_reason ?? (invoice.error as Status)?.value}
				</NetcurioAlert>
			</div>
		)
	}

	if (isStandaloneDocument) {
		return (
			<div className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Info} messagePadding="0">
					<div className={styles.verticalCenter}>
						<div className={styles.textCenter}>
							{isMissingLink
								? t('invoices.sendOrCancelAssociation')
								: t('invoices.reviewOrCancelInvoice')}
						</div>
					</div>
				</NetcurioAlert>
			</div>
		)
	}

	if (!seeMessageInvoiceError) {
		return false
	}

	return (
		<div className={styles.msgContainer}>
			<NetcurioAlert severity={Severity.Warning} messagePadding="0">
				<div className={styles.alertWithActionButtonContainer}>
					<div>{t('authorizeManualInvoice.reviewErrors')}</div>
					<NetcurioButton onClick={() => executeValidation()} sx={themeButtonReview}>
						{t('review')}
					</NetcurioButton>
				</div>
			</NetcurioAlert>
		</div>
	)
}

export default InvoiceAlert
